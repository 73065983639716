import React from "react";
import Card from '@material-ui/core/Card';
import cn from "classnames";
import "./style.css";

function BannerCardSideBar(props: any) {
    const handleBannerClick = () => {
        window.open('https://play.google.com/store/apps/details?id=com.emailwish.app', '_blank');
    };
    let classname = cn(
        "wide-side-card",
        props.className
    );
    return <Card className={classname} style={props.style} onClick={handleBannerClick}>{props.children}</Card>;

}

export default BannerCardSideBar;
